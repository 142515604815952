import React from "react";
import Layout from "../components/layout";
import NewsQuery from "../components/news/NewsQuery";

const News = () => (
  <Layout>
      <div>
          <h1>NEWS</h1>
          <div className='body'>
              <NewsQuery />
          </div>
      </div>
  </Layout>
);

export default News;
