import Moment from "moment";

const sort = (prop, order, arr) => {
  prop = prop.split(".");
  const len = prop.length;
  arr.sort(function (a, b) {
    let i = 0;
    let aName = '',
        bName = '';
    while( i < len ) {
      if(a['name']) {
        aName = a['name'];
        bName = b['name'];
      }
      a = a[prop[i]];
      b = b[prop[i]];
      i++;
    }
    if(!isNaN(a)) {
      a = parseInt(a, 10);
      b = parseInt(b, 10);
    }
    if(isNaN(a) && a.includes('%')) {
      a = a.match(/\d+/);
      b = b.match(/\d+/);
    }
    if(order === "DESC") {
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      } else {
        if(aName.length > 0) {
          if(aName > bName) {
            return -1;
          } else if (aName < bName) {
            return 1;
          }
        }
        return 0;
      }
    } else if (order === "ASC") {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    }
    return false;
  });

  return arr;
};
const sortNews = arr => {
  arr.sort((a, b) => {
    let i = 0;
    a = new Moment(a.childMarkdownRemark.frontmatter.date).format("MMMM Do YYYY, h:mm:ss a");
    b = new Moment(b.childMarkdownRemark.frontmatter.date).format("MMMM Do YYYY, h:mm:ss a");
    i++;
    return a - b;
  });
  return arr;
};

export { sort, sortNews };