import { graphql, StaticQuery } from "gatsby";
import React from "react";
import NewsList from "./NewsList";
import { sortNews } from "../helpers/sort";

const NewsQuery = () => (
    <StaticQuery
        query={graphql`
            query newsQuery {
              allFile(filter: {relativeDirectory: {eq: "news"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}) {
                edges {
                  node {
                    childMarkdownRemark {
                      frontmatter {
                        index
                        date
                        title
                        image
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => {
          const reworkedData = data.allFile.edges.map(key => {
            return key.node;
          });
          const newData = sortNews(reworkedData);
          return (
            <>
              <NewsList data={newData} />
            </>
          );
        }}
    />
);

export default NewsQuery;