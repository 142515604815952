import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import { Link } from "gatsby";
import "./news.css";

const NewsList = ({ data }) => (
  <>
      {
          data.map((news, i) => {
              const {
                  index,
                  date,
                  title,
                  image,
              } = news.childMarkdownRemark.frontmatter;

              return (
                <div className='news__item-container'>
                  <Link to={`/article/${index}/`} className="news__link-cover">
                    <div className='news__item'>
                      {image && (
                        <div className='news__image-container'>
                          <img src={image} className='news__image' alt={title} />
                        </div>
                      )}
                      <div className='news__container'>
                        <span className='news__date'>{new Moment(date).format("MMMM Do YYYY, h:mm:ss a")}</span>
                        <span className='news__title'>{title}</span>
                        <div className='news__link-container'>
                          <Link to={`/article/${index}/`} key={i} className='news__link'>
                            <span className='news__link-label'>Read</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
          })
      }
  </>
);

NewsList.propTypes = {
    data: PropTypes.array.isRequired,
};

export default NewsList;